@if (question && !editDisabled) {
  @if (form) {
    <div [formGroup]="form">
      @if (question.type === EventAttendeeQuestionType.DROPDOWN) {
        <mat-form-field
          class="{{ matFormFieldClass }}"
          [appearance]="matFormFieldAppearance"
        >
          <mat-label
            >{{ question.label }}{{ question.isRequired ? "*" : "" }}</mat-label
          >
          <mat-select formControlName="{{ 'question#' + question.id }}">
            <mat-option
              *ngFor="let option of question.options"
              [value]="option.id"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }
      @if (question.type === EventAttendeeQuestionType.TEXT) {
        <mat-form-field
          class="{{ matFormFieldClass }}"
          [appearance]="matFormFieldAppearance"
        >
          <mat-label
            >{{ question.label }}{{ question.isRequired ? "*" : "" }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="{{ 'question#' + question.id }}"
            placeholder=""
            [name]="question.label"
          />
        </mat-form-field>
      }
      @if (question.type === EventAttendeeQuestionType.TEXTAREA) {
        <mat-form-field
          class="{{ matFormFieldClass }} textarea-field"
          [appearance]="matFormFieldAppearance"
        >
          <mat-label
            >{{ question.label }}{{ question.isRequired ? "*" : "" }}</mat-label
          >
          <textarea
            matInput
            formControlName="{{ 'question#' + question.id }}"
            rows="8"
          ></textarea>
        </mat-form-field>
      }
      @if (question.type === EventAttendeeQuestionType.NUMBER) {
        <mat-form-field
          class="{{ matFormFieldClass }}"
          [appearance]="matFormFieldAppearance"
        >
          <mat-label
            >{{ question.label }}{{ question.isRequired ? "*" : "" }}</mat-label
          >
          <input
            type="number"
            matInput
            formControlName="{{ 'question#' + question.id }}"
            placeholder=""
            [name]="question.label"
          />
        </mat-form-field>
      }
      @if (question.type === EventAttendeeQuestionType.CHECKBOX) {
        <div class="checkbox-field">
          <label>{{ question.label }}</label>
          <div>
            <label class="toggle-check">
              <input
                (change)="onChange('question#' + question.id)"
                type="checkbox"
                class="toggle-check-input"
                formControlName="{{ 'question#' + question.id }}"
              />
              <span class="toggle-check-text"></span>
            </label>
          </div>
        </div>
      }
    </div>
  } @else {
    @if (question.type === EventAttendeeQuestionType.DROPDOWN) {
      <mat-form-field
        class="{{ matFormFieldClass }}"
        [appearance]="matFormFieldAppearance"
        (click)="$event.stopPropagation()"
      >
        <mat-select
          [ngModel]="ngModelVal"
          placeholder="{{ 'APP.EVENT_RSVP.NO_ANSWER' | translate }}"
        >
          @if (!question.isRequired) {
            <mat-option
              [value]="undefined"
              (onSelectionChange)="onChange('question#' + question.id)"
              (click)="onNgModelChange(undefined)"
              class="reset-value-option"
            >
              {{ "APP.EVENT_RSVP.NO_ANSWER" | translate }}
            </mat-option>
          }
          <mat-option
            *ngFor="let option of question.options"
            [value]="option.id"
            (click)="onNgModelChange(option.id)"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    }

    @if (question.type === EventAttendeeQuestionType.CHECKBOX) {
      <mat-form-field
        *ngIf="question.type === 'CHECKBOX'"
        class="{{ matFormFieldClass }}"
        [appearance]="matFormFieldAppearance"
        (click)="$event.stopPropagation()"
        (change)="onChange('question#' + question.id)"
      >
        <mat-select
          [ngModel]="ngModelVal"
          placeholder="{{ 'APP.NO' | translate }}"
          (ngModelChange)="onNgModelChange($event)"
        >
          <mat-option [value]="false">
            {{ "APP.NO" | translate }}
          </mat-option>

          <mat-option [value]="true">
            {{ "APP.YES" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    }

    @if (question.type === EventAttendeeQuestionType.TEXT) {
      <div
        text-editable
        class="table-text-editable"
        [attr.placeholder]="'APP.ENTER_ANSWER' | translate"
        [(ngModel)]="ngModelVal"
        (ngModelChange)="onNgModelChange($event)"
      >
        {{ question.label }}
      </div>
    }

    @if (question.type === EventAttendeeQuestionType.TEXTAREA) {
      <div
        text-editable
        class="table-text-editable"
        [attr.placeholder]="'APP.ENTER_ANSWER' | translate"
        [(ngModel)]="ngModelVal"
        (ngModelChange)="onNgModelChange($event)"
      >
        {{ question.label }}
      </div>
    }

    @if (question.type === EventAttendeeQuestionType.NUMBER) {
      <div class="number-type">
        <input
          matInput
          type="number"
          placeholder="{{ 'APP.EVENT_RSVP.NO_ANSWER' | translate }}"
          [(ngModel)]="ngModelVal"
          (ngModelChange)="onNgModelChange($event)"
        />
      </div>
    }
  }
}
